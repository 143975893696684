/* Center the content vertically and horizontally */
.center-container-forgot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; /* Add some padding for small screens */
}

/* Container for the forgot password form */
.forgot-password-container {
  text-align: center;
  max-width: 400px;
  width: 90%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  position: relative;
}

/* Form container with flex layout */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Label styles */
label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
  text-align: left;
  display: block;
}

/* Input styles */
.form-container input {
  width: 80%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for input border and shadow */
}

input:focus {
  border-color: #007bff; /* Border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow on focus */
}

/* Button styles */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.send-reset-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #007bff; /* Blue primary button color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Smooth transition for button background */
}

.send-reset-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.goto-reset-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #28a745; /* Green button color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Smooth transition */
}

.goto-reset-button:hover {
  background-color: #218838; /* Darker shade on hover */
}

/* Message and error styles */
.message, .error {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.message {
  background-color: #d4edda; /* Green background for success message */
  color: #155724;
}

.error {
  background-color: #f8d7da; /* Red background for error message */
  color: #721c24;
  width: 100%;
}

.back-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin: 20px auto;
  padding: 10px 20px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.back-button:hover {
  background-color: #ddd;
  color: #000;
}

.need-help-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #307DFF;
  text-decoration: none;
}

.need-help-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .forgot-password-container{
    margin-left: 1rem;
    margin-right: 1rem;
  }
}