/* ResetPasswordPage.css */

/* Center the content vertically and horizontally */
.center-container-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
/* Container for the reset password form */
.reset-password-container {
  text-align: center;
  max-width: 400px;
  width: 90%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Form container with flex layout */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

/* Input styles */
.form-container input {
  width: 80%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease-in-out; /* Smooth transition for input border */
}

input:focus {
  border-color: #007bff; /* Border color on focus */
}

/* Button styles */
.button-reset {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #007bff; /* Blue primary button color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Smooth transition for button background */
  margin-bottom: 8px;
}

.button-reset:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Message and error styles */
.message, .error {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.message {
  background-color: #d4edda; /* Green background for success message */
  color: #155724;
}

.error {
  background-color: #f8d7da; /* Red background for error message */
  color: #721c24;
}

/* Spinner container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Additional styling for better spacing and alignment */
.h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333; /* Dark grey for the header */
}

p {
  margin: 0;
}

.form-container input:disabled,
button:disabled {
  background-color: #e9ecef; /* Light grey background for disabled inputs and buttons */
  cursor: not-allowed;
}

.back-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin: 10px auto;
  padding: 10px 20px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.back-button:hover {
  background-color: #ddd;
  color: #000;
}
.need-help-link {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #307DFF;
  text-decoration: none;
}

.need-help-link:hover {
  text-decoration: underline;
}

  /* Password requirements styling */
.password-requirements {
  font-size: 1rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left; /* Align text to the left for better readability */
  width: 80%;
}

.password-requirements li {
  color: black; /* Default text color for requirements */
  position: relative;
  padding-left: 14px; /* Space for the bullet */
}

.password-requirements li::before {
  content: '•'; /* Bullet point symbol */
  position: absolute;
  left: 0;
  color: red; /* Default bullet color */
  font-size: 1.2rem;
  line-height: 1.1;
}

.password-requirements li.valid::before {
  color: green; /* Bullet color for met requirements */
}