/* Overlay background to dim the page */
.overlay {
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure it's above other content */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  overflow: auto; /* Allow scrolling if needed */
}

/* Modal container styling */
.remove-user-form-modal {
  position: relative; /* Positioned within the overlay */
  width: 90%; /* Use 90% of the viewport width to ensure it fits well */
  max-width: 40rem; /* Fixed max-width for the modal */
  max-height: 80vh; /* Maximum height of the modal, 90% of the viewport height */
  padding: 2rem; /* Internal padding */
  background-color: #fff; /* White background for the card */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Soft shadow for floating effect */
  z-index: 1100; /* Ensure it's above the overlay background */
  text-align: center; /* Center the text */
  font-family: "Arial", sans-serif; /* Clean font */
  box-sizing: border-box; /* Include padding and border in element's total width/height */
  overflow-y: auto; /* Enable vertical scrolling within the modal */
  transition: max-height 0.3s ease-in-out;
}

.remove-user-form-modal.dropdown-open {
  max-height: 90vh; /* Increase max-height when dropdown is open */
  overflow-y: visible; /* Ensure it scrolls if content is still too tall */
}

.remove-user-form-modal .error {
  width: 100%;
}

/* Close button styling */
.remove-user-form-modal button.close-button {
  position: absolute; /* Positioned in relation to the modal */
  top: 10px;
  right: 10px;
  background-color: transparent; /* No background */
  border: none; /* No border */
  font-size: 1.5rem; /* Larger font size */
  cursor: pointer; /* Pointer cursor on hover */
  color: #aaa; /* Light color */
  transition: color 0.3s ease; /* Smooth transition */
}

.remove-user-form-modal button.close-button:hover {
  color: #333; /* Darken on hover */
}

/* Form element styling */
.remove-user-form-modal form {
  display: flex; /* Use flex layout */
  flex-direction: column; /* Stack inputs vertically */
  gap: 1rem; /* Space between inputs */
}

/* Input and select styling */
.remove-user-form-modal input,
.remove-user-form-modal select {
  width: 100%; /* Full width of the form */
  padding: 0.75rem; /* Internal padding */
  border: 1px solid #ccc; /* Light border */
  border-radius: 8px; /* Rounded corners */
  font-size: 1rem; /* Consistent font size */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Slight inner shadow */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  box-sizing: border-box; /* Include padding and border in element's total width/height */
}

.remove-user-form-modal input:focus,
.remove-user-form-modal select:focus {
  border-color: #007bff; /* Highlight border on focus */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); /* Focus shadow */
  outline: none; /* remove default focus outline */
}

/* Submit button styling */
.remove-user-form-modal button[type="submit"] {
  width: 100%; /* Full width of the form */
  max-width: calc(
    100% - 2px
  ); /* Ensure button stays within the container's width */
  padding: 0.75rem; /* Internal padding */
  border: none; /* remove border */
  border-radius: 8px; /* Rounded corners */
  background: linear-gradient(
    135deg,
    #6a8dff,
    #3a6dff
  ); /* Blue gradient background */
  color: white; /* White text color */
  font-size: 1rem; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  margin-top: 1rem;
}

.remove-user-form-modal button[type="submit"]:hover {
  background: linear-gradient(
    135deg,
    #3a6dff,
    #6a8dff
  ); /* Reverse gradient on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.remove-user-form-modal button[type="submit"]:active {
  transform: translateY(1px); /* Slight push effect on click */
}

/* Custom dropdown styles */
.dropdown-container {
  position: relative; /* Relative positioning for the dropdown */
  display: flex;
  flex-direction: column;
}

.dropdown-input {
  width: 100%; /* Full width of the form */
  max-width: calc(
    100% - 2px
  ); /* Ensure inputs stay within the container's width */
  padding: 0.75rem; /* Internal padding */
  border: 1px solid #ccc; /* Light border */
  border-radius: 8px; /* Rounded corners */
  font-size: 1rem; /* Consistent font size */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Slight inner shadow */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  box-sizing: border-box; /* Include padding and border in element's total width/height */
}

.dropdown-menu {
  position: absolute; /* Positioning relative to the container */
  top: 100%; /* Below the input field */
  left: 0;
  right: 0;
  max-height: 200px; /* Maximum height of the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #fff; /* White background */
  border: 1px solid #ccc; /* Border to distinguish it */
  z-index: 1200; /* Ensure it is above other content */
}

.dropdown-item {
  padding: 10px; /* Padding for each item */
  cursor: pointer; /* Pointer cursor on hover */
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

/* Styles for the user section */
.users-section {
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-height: 40vh; /* Fixed height */
  overflow-y: auto; /* Enable scrolling if the content overflows */
}

/* Title for the user section */
.users-section h3 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: #333;
}

/* Styles for the user checkboxes container */
.users-checkboxes {
  /* remove overflow user to avoid double scrollbars */
  padding: 0.5rem;
}

/* Style for the semi-transparent overlay */
.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 10; /* Ensure it is above the form fields */
  border-radius: 11px;
}

/* Style for the Done button */
.done-button {
  position: relative;
  z-index: 20; /* Ensure the button is above the overlay */
  background-color: #307dff; /* Blue background */
  color: white; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; /* Space above the button */
  display: block; /* Make it block-level for easier positioning */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.done-button:hover {
  background-color: #1f52aa; /* Darker blue on hover */
}

/* Style for the success message popup */
.success-popup {
  position: fixed; /* Use fixed positioning to remain in place as the user scrolls */
  top: 20%; /* Position it closer to the top */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50; /* Green background for success */
  color: white; /* White text color */
  padding: 15px 30px; /* Adjust padding for better spacing */
  border-radius: 8px; /* Soften the rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  z-index: 20; /* Ensure it is above the overlay */
  text-align: center; /* Center align the text */
  font-size: 1rem; /* Slightly larger font size for clarity */
  width: auto; /* Allow width to adjust based on content */
  max-width: 80%; /* Prevent it from getting too wide */
  box-sizing: border-box; /* Include padding in the width */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center align items vertically */
  gap: 10px; /* Add some space between icon and text */
}

/* Icon styling for success message */
.success-popup::before {
  content: "✔"; /* Unicode check mark as a visual cue */
  font-size: 1.5rem; /* Larger icon size */
  display: inline-block; /* Inline block for proper spacing */
  margin-right: 10px; /* Space between icon and text */
  color: white; /* Icon color */
}

/* Title for the properties section */
.users-section h3 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: #333;
}

/* Styles for the properties checkboxes container */
.users-checkboxes {
  /* remove overflow properties to avoid double scrollbars */
  padding: 0.5rem;
}

/* Styles for each checkbox label */
.user-checkbox {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center align the checkbox and text */
  margin-bottom: 0.5rem; /* Space between each checkbox */
  cursor: pointer;
  font-size: 1rem;
  color: #555;
  width: 100%;
}

/* Styles for the checkbox input */
.user-checkbox input {
  width: 3%;
  margin-right: 0.5rem; /* Space between checkbox and label text */
  flex-shrink: 0; /* Prevent the checkbox from shrinking */
}

/* Ensure the text takes the remaining space and is visible */
.user-checkbox span {
  flex-grow: 1; /* Allow the text to grow and take up available space */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}
.bold {
  font-weight: bold; /* This makes the text bold */
}
