.floating-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  background: transparent; /* Ensure the background is transparent */
  padding: 10px 0;
  box-sizing: border-box;
  z-index: 1000;
}

.floating-footer p {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  margin: 0;
  font-size: 1.4rem;
  animation: floatText 5s ease-in-out infinite;
}

/*@keyframes floatText {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}*/
@media screen and (max-width: 660px) {
  .floating-footer p{
    font-size: .8rem;
  }
}
