/* Modal Overlay */
.documents-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.documents-modal {
  background-color: #fff;
  width: 90%;
  max-width: 800px;
  height: 90%;
  border-radius: 8px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Close Button */
.close-button {
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
  z-index: 2000;
}

/* Tabs */
.tabs {
  display: flex;
  justify-content: space-evenly;
  background-color: #f1f1f1;
  padding: 10px 0;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.tab.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.tab-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Dropdown Section */
.dropdown-section,
.properties-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-height: 20rem;
  overflow-y: visible;
  z-index: 1050;
}

/* Dropdown */
.dropdown {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  cursor: pointer;
}

.dropdown-header input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 5px;
}

.dropdown-header input::placeholder {
  color: #aaa;
}

.dropdown-header:focus-within {
  border-color: #007bff;
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.15);
}

.documents-dropdown-menu {
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1050; /* Ensures it overlays other elements */
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-option {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s;
}

.dropdown-option:hover,
.dropdown-option.selected {
  background: #f0f0f0;
}

.dropdown-loading,
.dropdown-no-results {
  padding: 10px;
  font-size: 14px;
  color: #999;
  text-align: center;
}

/* Properties Section */
.properties-section h3 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: #333;
}

.properties-checkboxes {
  padding: 0.5rem;
}

.property-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
}

.property-checkbox input {
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.property-checkbox span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Selected Items Section */
.selected-items {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f0fff0;
}

.selected-items h4 {
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.selected-items ul {
  list-style-type: disc;
  padding-left: 20px;
}

.selected-items li {
  margin-bottom: 5px;
  color: #555;
  text-align: start;
}
.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input {
  margin-bottom: 15px;
}

.upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.upload-message {
  margin-top: 10px;
  color: green;
}
.view-section {
  padding: 20px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.loading-message,
.error-message,
.no-documents-message {
  text-align: center;
  font-size: 16px;
  color: #777;
}

.documents-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.document-card {
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.document-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.document-title {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.document-uploaded {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}
.upload-warning {
  color: #d9534f;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.documents-list {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Space between rows */
  padding: 0; /* Remove extra padding */
}

.document-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px;
  background-color: #f9f9f9; /* Light background */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.document-row:hover {
  background-color: #f1f1f1; /* Slightly darker background on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Elevated hover effect */
}

.document-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 16px;
}

.document-title {
  font-size: 18px;
  font-weight: bold;
  color: #007bff; /* Blue for titles */
  margin: 0 0 8px;
  word-break: break-word;
}

.document-uploaded {
  font-size: 14px;
  color: #555; /* Subtle gray for timestamps */
  margin: 0;
}

.document-actions {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between action buttons */
}

.error-message,
.no-documents-message,
.loading-message {
  font-size: 16px;
  color: #777;
  text-align: center;
}
.dropdown-header-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}
.document-action-button,
.download-action-button,
.delete-action-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Adjust size as needed */
  color: #4caf50; /* Customize for your theme */
  padding: 8px;
}
.document-action-button:hover {
  color: #45a049;
}

.download-action-button:hover {
  color: #45a049; /* Darker green for Download hover */
}

.delete-action-button {
  color: #f44336; /* Red for delete */
}

.delete-action-button:hover {
  color: #d32f2f; /* Darker red for Delete hover */
}
.react-tooltip {
  background-color: #333;
  color: white;
  font-size: 12px;
  padding: 6px 8px;
  border-radius: 4px;
  text-align: center;
}
