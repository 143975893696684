/* Overlay background to dim the page */
.overlay-edit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 99999999; /* Ensure it covers everything */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  overflow: auto; /* Allow scrolling for tall content */
}
.nav-container.modal-active {
  background-color: rgba(0, 0, 0, 0.3); /* Darken the sidebar */
  color: #ccc; /* Adjust text color for better contrast */
  pointer-events: none; /* Prevent interaction with the sidebar */
}

/* Header darkened when modal is active */
.header.modal-active {
  background-color: rgba(0, 0, 0, 0.3); /* Darken the header */
  pointer-events: none; /* Prevent interaction with the header */
}
/* Modal container styling */
.edit-user-form-modal {
  position: relative;
  z-index: 2147483648; /* Higher than the overlay */
  width: 100%;
  max-width: 50rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 90vh;
}

.edit-user-form-modal.dropdown-open {
  max-height: 90vh;
  overflow-y: visible;
}

/* Close button styling */
.edit-user-form-modal button.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
}

.edit-user-form-modal button.close-button:hover {
  color: #333;
}

/* Add user button styling */
.edit-user-form-modal button.add-user-button {
  background-color: #1f52aa;
  position: absolute;
  top: 14px;
  left: 14px;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-user-form-modal button.add-user-button:hover {
  background-color: #16478c;
}

/* Form element styling */
.edit-user-form-modal form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Input and select styling */
.edit-user-form-modal input,
.edit-user-form-modal select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.edit-user-form-modal input:focus,
.edit-user-form-modal select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  outline: none;
}

/* Save button styling */
.edit-user-form-modal button[type="submit"] {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #6a8dff, #3a6dff);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.edit-user-form-modal button[type="submit"]:hover {
  background: linear-gradient(135deg, #3a6dff, #6a8dff);
  transform: translateY(-2px);
}

.edit-user-form-modal button[type="submit"]:active {
  transform: translateY(1px);
}

/* Dropdown styling */
.dropdown-container {
  position: relative;
}

.dropdown-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1200;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* Customer and property section */
.customers-properties-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.customers-section-edit,
.properties-section-edit {
  background-color: #f9f9f9;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.customers-section-edit h3,
.properties-section-edit h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.properties-list,
.customers-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.property-checkbox,
.customer-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.property-checkbox input,
.customer-checkbox input {
  margin-right: 10px;
  flex-shrink: 0;
}

/* Selected items section */
.selected-items {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.selected-items h4 {
  margin-bottom: 8px;
  font-size: 1.1rem;
  font-weight: bold;
}

.selected-items ul {
  list-style-type: disc;
  padding-left: 20px;
}

.selected-items li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1rem;
}

.remove-button {
  background: transparent;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
  font-size: 1.2rem;
}

.remove-button:hover {
  color: #ff1a1a;
}

/* Success popup styling */
.success-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 15px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
  text-align: center;
  font-size: 1rem;
  width: auto;
  max-width: 80%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.success-popup::before {
  content: "✔";
  font-size: 1.5rem;
  color: white;
}

/* Done button styling */
.done-button {
  background-color: #307dff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.done-button:hover {
  background-color: #1f52aa;
}

/* Loading spinner styling */
.loading-spinner {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #555;
}

.loading-spinner::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #555;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
