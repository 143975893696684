/* Main wrapper background */
.homepage-wrapper {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  border-radius: 0;
  display: flex;
  background-color: #f7f7f7;
  overflow: hidden;
  position: relative;
}
.homepage-content {
  width: 100%;
}

/* Sidebar styling to match white modern theme */
.report-list-container {
  width: 240px;
  padding: 20px;
  background-color: #f7f7f7; /* White background for sidebar */
  color: #333; /* Dark text color for readability */
}

/* Report list items */

/* Sub-report list */
.sub-report-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.sub-report-list li {
  font-size: 1rem;
  color: #666;
  padding: 10px;
}

.sub-report-list li:hover::after {
  background-color: #307bff;
}

/* Header styling to match white theme */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7f7; /* White header */
  color: #333;
  padding: 10px;
  width: 100%;
  z-index: 190;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.customer-name {
  font-size: 16px;
  color: #333;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}
.custom-dropdown {
  position: relative;
}

.customer-search-input {
  width: 250px;
  padding: 0.4rem 0.6rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.customer-search-input:focus {
  border-color: #307bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  outline: none;
}

/* Left buttons container */
.left-buttons-container {
  width: 10rem;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.home-button {
  background: none;
  border: none;
  color: #307bff; /* Original color for icons */
  width: 26%;
  z-index: 100;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.home-button:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Original hover effect */
}

.home-button img {
  width: 100%;
  height: 100%;
  filter: brightness(0) saturate(100%) invert(30%) sepia(97%) saturate(7463%)
    hue-rotate(212deg) brightness(95%) contrast(101%);
}

.selected-report-name {
  color: #307bff;
  font-weight: 600;
  margin-left: 4px;
}

/* Admin Controls */
.admin-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin-title {
  color: #333;
  font-weight: bold;
}

/* Buttons Styling */
.add-user-button,
.upload-image-button {
  font-size: 14px;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  background-color: #307bff; /* Blue button color */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-user-button:hover,
.upload-image-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px);
}

/* Customer list styling */
.customer-list {
  background-color: #ffffff; /* White background */
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #333;
  font-size: 12px;
  padding: 0.3rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Dropdown Options */
.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.dropdown-option {
  padding: 8px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-option:hover {
  background-color: #f0f8ff;
  color: #307bff;
}

/* Signup and Edit User Form Modals */
.signup-form,
.edit-user-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.signup-form-container,
.edit-user-form-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.reports-grid {
  display: grid;
  grid-template-columns: 1fr; /* Only one column across the full width */
  grid-auto-rows: minmax(
    200px,
    auto
  ); /* Rows adapt between 200px and the size of the content */
  gap: 0px; /* No gap between rows */
  width: 100%; /* Ensure grid takes full width */
  height: calc(
    100vh - 50px
  ); /* Adjust this value to account for other page elements */
  margin: 0 auto; /* Center grid horizontally */
}
.report {
  width: 100%;
  height: 100%;
}
.controls-container {
  display: flex;
  margin-right: 2rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1300px) {
  .home-button {
    margin-left: 1rem;
  }
  .customer-name {
    font-size: 12px;
  }
}
