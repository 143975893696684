.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;
  height: 18rem;
  background: #ffffff00;
  margin-top: 14rem;
}

.logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.login-container {
  flex: 1;
  color: #fff;
  max-width: 400px;
  width: 100%;
  background-color: #00000033;
  margin: 0;
  padding: 20px; /* Add padding inside the container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box; /* Include padding in the total width and height */
}

.login-container h1 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: center; /* Ensure title is centered */
}

.logo-container img {
  width: 90%;
  display: block;
  filter: brightness(1.1) contrast(1.1);
  margin-bottom: 2rem;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container input, .form-container button {
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.938);
  color: #004b87;
  font-size: 1rem;
}

.form-container input::placeholder, .form-container button {
  color: #004b87;
  font-size: 1rem;
}

.form-container button {
  cursor: pointer;
  background: #3183ff;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 1.2rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

.form-container button:hover {
  background: #2563c0;
  border-color: #fff;
  color: #e0e0e0;
}

.error {
  color: #f44336;
  width: 80%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 5px 0; /* Add padding to ensure spacing around the text */
  box-sizing: border-box; /* Include padding in the total width */
}

.forgot-password-link {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #f8f8f8;
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease;
  text-align: center; /* Ensure text is centered */
  text-decoration: none; /* Remove underline by default */
}

.forgot-password-link:hover,
.forgot-password-link:focus {
  text-decoration: underline; /* Underline on hover/focus */
  color: #e0e0e0; /* Change color on hover/focus for contrast */
}

.need-help-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: blue;
  text-decoration: none;
}

.need-help-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1240px) {
  .login-container{
    max-width: 15rem;

  }
}
@media screen and (max-width: 970px) {
  .center-container{
    flex-direction: column;
    
  }
  .login-container{
    max-width: 26rem;
    

  }
}

@media screen and (max-width: 680px) {
  .center-container{
    flex-direction: column;
    width: 80%;
    margin-top: 12rem;
  }
}
