.menu {
  position: relative;
  display: inline-block;
}

.icon {
  background: none;
  border: none;
  color: #0f0f0fd4;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px; /* Space between text and arrow */
}
.name .arrow {
  display: inline-block;
  transition: transform 0.3s ease; /* Smooth transition for arrow flipping */
}
.name {
  font-weight: 600;
  font-family: inherit;
}

.arrow.down {
  transform: rotate(0deg);
}

.arrow.up {
  transform: rotate(180deg);
}

.menu-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  right: 0;
}
.menu-content a {
  text-decoration: none;
}

.menu-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out; /* Transition for smooth color change */
}

.menu-content button:hover {
  background-color: #307dff; /* Blue background on hover */
  color: white; /* White text on hover */
}
@media (max-width: 1300px) {
  .name {
    font-size: 12px;
  }
}
