/* Overlay background to dim the page */
.overlay-signup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 99999999; /* Ensure it covers everything */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  overflow: auto; /* Allow scrolling for tall content */
}

/* Modal container styling */
.signup-form-modal {
  position: relative;
  width: 100%;
  max-width: 50rem; /* Responsive max-width */
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  z-index: 1100;
  text-align: center;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 90vh; /* Prevent the modal from exceeding the viewport height */
}

.signup-form-modal .error {
  width: 100%;
}

/* Close button styling */
.signup-form-modal button.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
}

.signup-form-modal button.close-button:hover {
  color: #333;
}

/* Form element styling */
.signup-form-modal form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Input and select styling */
.signup-form-modal input,
.signup-form-modal select {
  width: 100%; /* Full width of the form */
  padding: 0.75rem; /* Internal padding */
  border: 1px solid #ccc; /* Light border */
  border-radius: 8px; /* Rounded corners */
  font-size: 1rem; /* Consistent font size */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Slight inner shadow */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  box-sizing: border-box; /* Include padding and border in element's total width/height */
}

.signup-form-modal input,
.signup-form-modal select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.signup-form-modal input:focus,
.signup-form-modal select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  outline: none;
}
/* Submit button styling */
.signup-form-modal button[type="submit"] {
  width: 100%; /* Full width of the form */
  max-width: calc(
    100% - 2px
  ); /* Ensure button stays within the container's width */
  padding: 0.75rem; /* Internal padding */
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  background: linear-gradient(
    135deg,
    #6a8dff,
    #3a6dff
  ); /* Blue gradient background */
  color: white; /* White text color */
  font-size: 1rem; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

.signup-form-modal button[type="submit"]:hover {
  background: linear-gradient(
    135deg,
    #3a6dff,
    #6a8dff
  ); /* Reverse gradient on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.signup-form-modal button[type="submit"]:active {
  transform: translateY(1px); /* Slight push effect on click */
}

/* Custom dropdown styles */
.dropdown-container {
  position: relative; /* Relative positioning for the dropdown */
  display: flex;
  flex-direction: column;
}
/* Sidebar darkened when modal is active */
.nav-container.modal-active {
  background-color: rgba(0, 0, 0, 0.3); /* Darken the sidebar */
  color: #ccc; /* Adjust text color for better contrast */
  pointer-events: none; /* Prevent interaction with the sidebar */
}

/* Header darkened when modal is active */
.header.modal-active {
  background-color: rgba(0, 0, 0, 0.3); /* Darken the header */
  pointer-events: none; /* Prevent interaction with the header */
}

.dropdown-input {
  width: 100%; /* Full width of the form */
  max-width: calc(
    100% - 2px
  ); /* Ensure inputs stay within the container's width */
  padding: 0.75rem; /* Internal padding */
  border: 1px solid #ccc; /* Light border */
  border-radius: 8px; /* Rounded corners */
  font-size: 1rem; /* Consistent font size */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Slight inner shadow */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  box-sizing: border-box; /* Include padding and border in element's total width/height */
}

.dropdown-menu {
  position: absolute; /* Positioning relative to the container */
  top: 100%; /* Below the input field */
  left: 0;
  right: 0;
  max-height: 200px; /* Maximum height of the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #fff; /* White background */
  border: 1px solid #ccc; /* Border to distinguish it */
  z-index: 1000; /* Ensure it is above other content */
}

.dropdown-item {
  padding: 10px; /* Padding for each item */
  cursor: pointer; /* Pointer cursor on hover */
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

/* Styles for the properties section */
.properties-section {
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  height: 20rem; /* Fixed height */
  overflow-y: auto; /* Enable scrolling if the content overflows */
}

/* Title for the properties section */
.properties-section h3 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: #333;
}

/* Styles for the properties checkboxes container */
.properties-checkboxes {
  /* Remove overflow properties to avoid double scrollbars */
  padding: 0.5rem;
}

/* Styles for each checkbox label */
.property-checkbox {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center align the checkbox and text */
  margin-bottom: 0.5rem; /* Space between each checkbox */
  cursor: pointer;
  font-size: 1rem;
  color: #555;
  width: 100%;
}

/* Styles for the checkbox input */
.property-checkbox input {
  width: 2%;
  margin-right: 0.5rem; /* Space between checkbox and label text */
  flex-shrink: 0; /* Prevent the checkbox from shrinking */
}

/* Ensure the text takes the remaining space and is visible */
.property-checkbox span {
  flex-grow: 1; /* Allow the text to grow and take up available space */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

/* Style for the semi-transparent overlay */
.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 10; /* Ensure it is above the form fields */
  border-radius: 11px;
}

/* Style for the Done button */
.done-button {
  position: relative;
  z-index: 20; /* Ensure the button is above the overlay */
  background-color: #307dff; /* Blue background */
  color: white; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; /* Space above the button */
  display: block; /* Make it block-level for easier positioning */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.done-button:hover {
  background-color: #1f52aa; /* Darker blue on hover */
}

/* Style for the success message popup */
.success-popup {
  position: fixed; /* Use fixed positioning to remain in place as the user scrolls */
  top: 20%; /* Position it closer to the top */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50; /* Green background for success */
  color: white; /* White text color */
  padding: 15px 30px; /* Adjust padding for better spacing */
  border-radius: 8px; /* Soften the rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  z-index: 20; /* Ensure it is above the overlay */
  text-align: center; /* Center align the text */
  font-size: 1rem; /* Slightly larger font size for clarity */
  width: auto; /* Allow width to adjust based on content */
  max-width: 80%; /* Prevent it from getting too wide */
  box-sizing: border-box; /* Include padding in the width */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center align items vertically */
  gap: 10px; /* Add some space between icon and text */
}

/* Icon styling for success message */
.success-popup::before {
  content: "✔"; /* Unicode check mark as a visual cue */
  font-size: 1.5rem; /* Larger icon size */
  display: inline-block; /* Inline block for proper spacing */
  margin-right: 10px; /* Space between icon and text */
  color: white; /* Icon color */
}
/* Add these styles to your SignupForm.css file */

/* Individual checkboxes styling */
.customer-checkbox,
.property-checkbox {
  display: block; /* Ensure each checkbox is on a new line */
  margin-bottom: 8px; /* Optional: Add space between checkboxes */
}

.selected-items {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f0fff0;
}

.selected-items h4 {
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.selected-items ul {
  list-style-type: disc;
  padding-left: 20px;
}

.selected-items li {
  margin-bottom: 5px;
  color: #555;
  text-align: start;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
