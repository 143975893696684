/* Overlay background to dim the page */
.overlay {
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 999; /* Ensure it's above other content */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    overflow: auto; /* Allow scrolling if needed */
  }
  
  /* Modal container styling */
  .upload-image-form {
    position: relative; /* Positioned within the overlay */
    width: 90%; /* Use 90% of the viewport width to ensure it fits well */
    max-width: 40rem; /* Fixed max-width for the modal */
    max-height: 80vh; /* Maximum height of the modal, 80% of the viewport height */
    padding: 2rem; /* Internal padding */
    background-color: #fff; /* White background for the card */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Soft shadow for floating effect */
    z-index: 1000; /* Ensure it's above the overlay background */
    text-align: center; /* Center the text */
    font-family: 'Arial', sans-serif; /* Clean font */
    box-sizing: border-box; /* Include padding and border in element's total width/height */
    overflow-y: auto; /* Enable vertical scrolling within the modal */
  }
  
  .upload-image-form .error-message,
  .upload-image-form .success-message {
    color: white; /* White text color */
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .upload-image-form .error-message {
    background-color: #ff4d4d; /* Red background for errors */
  }
  
  .upload-image-form .success-message {
    background-color: #4CAF50; /* Green background for success */
  }
  
  /* Close button styling */
  .upload-image-form button.close-button {
    position: absolute; /* Positioned in relation to the modal */
    top: 10px;
    right: 10px;
    background-color: transparent; /* No background */
    border: none; /* No border */
    font-size: 1.5rem; /* Larger font size */
    cursor: pointer; /* Pointer cursor on hover */
    color: #aaa; /* Light color */
    transition: color 0.3s ease; /* Smooth transition */
  }
  
  .upload-image-form button.close-button:hover {
    color: #333; /* Darken on hover */
  }
  
  /* Form element styling */
  .upload-image-form form {
    display: flex; /* Use flex layout */
    flex-direction: column; /* Stack inputs vertically */
    gap: 1rem; /* Space between inputs */
  }
  
  /* Input and select styling */
  .upload-image-form input,
  .upload-image-form select {
    width: 100%; /* Full width of the form */
    padding: 0.75rem; /* Internal padding */
    border: 1px solid #ccc; /* Light border */
    border-radius: 8px; /* Rounded corners */
    font-size: 1rem; /* Consistent font size */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Slight inner shadow */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    box-sizing: border-box; /* Include padding and border in element's total width/height */
  }
  
  .upload-image-form input:focus,
  .upload-image-form select:focus {
    border-color: #007bff; /* Highlight border on focus */
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); /* Focus shadow */
    outline: none; /* Remove default focus outline */
  }
  
  /* Submit button styling */
  .upload-image-form button[type="submit"] {
    width: 100%; /* Full width of the form */
    padding: 0.75rem; /* Internal padding */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    background: linear-gradient(135deg, #6a8dff, #3a6dff); /* Blue gradient background */
    color: white; /* White text color */
    font-size: 1rem; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
  
  .upload-image-form button[type="submit"]:hover {
    background: linear-gradient(135deg, #3a6dff, #6a8dff); /* Reverse gradient on hover */
    transform: translateY(-2px); /* Lift effect on hover */
  }
  
  .upload-image-form button[type="submit"]:active {
    transform: translateY(1px); /* Slight push effect on click */
  }
  
  /* Cancel button styling */
  .upload-image-form button[type="button"] {
    width: 100%; /* Full width of the form */
    padding: 0.75rem; /* Internal padding */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    background: #ccc; /* Grey background */
    color: white; /* White text color */
    font-size: 1rem; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    margin-top: 1rem; /* Space above the button */
    transition: background 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
  
  .upload-image-form button[type="button"]:hover {
    background: #bbb; /* Darker grey on hover */
    transform: translateY(-2px); /* Lift effect on hover */
  }
  
  .upload-image-form button[type="button"]:active {
    transform: translateY(1px); /* Slight push effect on click */
  }
  /* Close button styling */
.signup-form-modal button.close-button {
  position: absolute; /* Positioned in relation to the modal */
  top: 10px;
  right: 10px;
  background-color: transparent; /* No background */
  border: none; /* No border */
  font-size: 1.5rem; /* Larger font size */
  cursor: pointer; /* Pointer cursor on hover */
  color: #aaa; /* Light color */
  transition: color 0.3s ease; /* Smooth transition */
}

.signup-form-modal button.close-button:hover {
  color: #333; /* Darken on hover */
}